import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelpArticle, SubTitle } from 'components/help/helpArticle';
import { StyledH4 } from 'components/help/helpArticle/styled';
import Code from 'components/help/codeSnippet';
import Link from 'components/ui/link';
import logo from 'img/companyLogo/survicate.png';
import Img from 'gatsby-image';

const Content = () => {
  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativeDirectory: { eq: "marketplace/survicate" } }
        sort: { fields: base, order: ASC }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);
  return (
    <div>
      <SubTitle>What is Survicate?</SubTitle>
      <p>
        Survicate is a tool that helps you get more feedback from your customers. Connecting it with
        LiveSession and:
      </p>
      <ul>
        <li>Access the session recordings from the respondent profile</li>
        <li>
          Filter session recordings via custom properties, e.g. survey name, ID, title, description,
          or answer
        </li>
        <li>Help your customers more easily and efficiently</li>
      </ul>
      <SubTitle className="mt-5">How does it work?</SubTitle>
      <StyledH4>Survicate</StyledH4>
      <p>
        Every respondent profile in Survicate gets a new property called <i>sessionURL</i>. It
        contains a URL with the session recording. This way, you can see how the surveyed user has
        interacted with the website:
      </p>
      <Img
        fluid={edges[0].node.childImageSharp.fluid}
        alt="View profile"
        title="Survicate respondent profile"
      />
      <StyledH4>LiveSession</StyledH4>
      <p>After every completed survey, integration sends data to LiveSession.</p>
      <p>
        In the LiveSession app, you can use Survicate data as <strong>custom properties</strong> for
        filtering. If you’d like to preview the properties, hover your mouse over the{' '}
        <i>properties</i> of the selected session:
      </p>
      <Img
        fluid={edges[7].node.childImageSharp.fluid}
        alt="Instruction in LiveSession App"
        title="User properties"
      />
      <SubTitle className="mt-5">How can I install this integration?</SubTitle>
      <span>To install integration, add the code below before your </span>{' '}
      <Code inline>{'</body>'}</Code> <span> closing tag:</span>
      <Code margin="1rem 0" copy lang="js">
        {`<script type="text/javascript">
__ls("getSessionURL",function(s,t){!function(t){function i(){t._sva.setVisitorTraits({sessionURL:s})}
t._sva?(i(),t._sva.addEventListener("survey_completed",function(s){!function(){const{surveyState:s,backendData:t,visit:i}=_sva.getState(),e=1===s.pointsDisplayed.length&&s.pointsDisplayed[0].point.id,n=i.answers[e]&&{"survicate.last_answer":i.answers[e]}
,a=s.pointsDisplayed[0].point.description&&{"survicate.last_description":s.pointsDisplayed[0]
.point.description},o=s.pointsDisplayed[0].point.content&&{"survicate.last_title":s.pointsDisplayed[0].point.content};__ls("setCustomParams",{params:{"survicate.last_survey_name":t.surveys[0].name,"survicate.survey_id":t.surveys[0].id,...o,...a,...n}})}()})):t.addEventListener("SurvicateReady",function(){i()})}(window)});
</script>
`}
      </Code>
      <div className="alert alert-primary mb-5 mt-5" role="alert">
        To make this integration work, you need to have both Survicate and LiveSession installed on
        your website. If you haven&apos;t installed our tracking code yet, see{' '}
        <Link href="/help/how-to-install-livesession-script/">this article</Link>. To install
        Survicate,{' '}
        <a
          href="https://help.survicate.com/en/articles/3933915-getting-a-survey-appear-on-your-website-installing-and-testing-tracking-code"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          follow this guide{' '}
        </a>
        .
      </div>
      <SubTitle className="mt-5">
        How to filter sessions with custom properties from Survicate?
      </SubTitle>
      <p>
        Session recording from Survicate will come with a few custom properties. You can use them as
        filters to find specific sessions faster.
      </p>
      <p>Here are all of the available custom properties:</p>
      <table className="table table-bordered table-responsive mt-5 mb-5">
        <tbody>
          <tr>
            <td>
              <i>survicate.last_survey_name</i>
            </td>
            <td>Name of your survey</td>
          </tr>
          <tr>
            <td>
              <i>survicate.survey_id</i>
            </td>
            <td>ID of your survey</td>
          </tr>
          <tr>
            <td>
              <i>survicate.last_description </i>
            </td>
            <td>Description of your survey (only if exists)</td>
          </tr>
          <tr>
            <td>
              <i>survicate.last_title</i>
            </td>
            <td>Title of your survey (only if exists)</td>
          </tr>
          <tr>
            <td>
              <i>survicate.last_answer</i>
            </td>
            <td>
              Answer of survey (only if exists and survey presents one question, for ex. Customer
              Effort Score)
            </td>
          </tr>
        </tbody>
      </table>
      <ol>
        <li>
          <p>
            Click <strong>Add filter &gt; Custom param</strong>
          </p>
          <Img
            fluid={edges[2].node.childImageSharp.fluid}
            alt="Instruction in LiveSession App"
            title="Custom param"
          />
        </li>
        <li>
          <p>
            Select the first text field and choose your filter. We filter Survicate surveys by their
            ID:
          </p>
          <Img
            fluid={edges[3].node.childImageSharp.fluid}
            alt="Instruction in LiveSession App"
            title="Choose filter"
          />
        </li>
        <li>
          <p>Search for the property you need: </p>
          <Img
            fluid={edges[4].node.childImageSharp.fluid}
            alt="Instruction in LiveSession App"
            title="Find your property"
          />
        </li>
        <li>
          <p>
            Click the <strong>Apply filters</strong> button. And that’s all, you’ve got filtered
            sessions!
          </p>
        </li>
      </ol>
      <p>
        We highly recommend saving these filters as a{' '}
        <Link href="/blog/how-to-use-filters-in-user-research/">segment</Link>. This way, you can
        easily come back and find the right sessions even faster! Take a look at this short guide:
      </p>
      <ol>
        <li>
          <p>
            Click <strong>Save as segment</strong>:
          </p>
          <Img
            fluid={edges[5].node.childImageSharp.fluid}
            alt="Instruction in LiveSession App"
            title="Save as Segment"
          />
        </li>
        <li>
          <p>
            Define the <i>Segment name</i> and click <strong>Create segment</strong>. Your segments
            will be visible on the left side under My segments:{' '}
          </p>
          <Img
            fluid={edges[6].node.childImageSharp.fluid}
            alt="Instruction in LiveSession App"
            title="Fill the Segment name"
          />
        </li>
      </ol>
    </div>
  );
};

const related = [
  {
    title: 'How to check if my tracking script works?',
    description: 'Check if your tracking code is installed correctly',
    link: '/help/how-to-check-if-my-tracking-script-works/',
  },
  {
    title: 'LiveSession and User.com integration',
    link: '/help/user-integration/',
    description: 'Go to session replay directly from User converstion.',
  },
];

export const frontmatter = {
  metaTitle: 'LiveSession and Survicate integration',
  canonical: '/help/survicate-integration/',
  metaDescription: 'Add LiveSession recordings to form submit details in Survicate.',
  logo,
};

const Wrapped = HelpArticle(Content);
export default () => (
  <Wrapped
    related={related}
    section="Integrations"
    title={frontmatter.metaTitle}
    {...frontmatter}
  />
);
